import React, {useMemo, useState} from "react";
import Icon from "@mdi/react";
import {mdiCog, mdiFilePdfBox, mdiListStatus, mdiBadgeAccount } from "@mdi/js";
import SvgIcon from "@mui/material/SvgIcon";
import context from "../context.json";
import {AccountBoxTwoTone, DashboardTwoTone} from "@mui/icons-material";
import * as _movimentacaoService from "../service/movimentacoes-service";
import {useRecoilValue} from "recoil";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import tipoMovimentacaoEnum from "../utils/tipoMovimentacaoEnum";
import {permissionsState} from "../recoil/atoms/permissionsState";


interface Item {
  title: string;
  path?: string;
  icon?: React.ReactNode;
  items?: Item[];
}

interface Section {
  subheader?: string;
  items: Item[];
  restricted?: boolean;
}

const sections: Section[] = [
  {
    subheader: "Secretaria Web",
    items: [
      {
        title: "Início",
        path: "/home",
        icon: (
          <SvgIcon fontSize="small">
            <DashboardTwoTone/>
          </SvgIcon>
        )
      },
    ]
  },
  {
    items: [
      {
        title: "Matrícula",
        path: `matricula`,
        icon: (
          <SvgIcon fontSize="small">
            <AccountBoxTwoTone/>
          </SvgIcon>
        ),
        items: [
          {
            title: "Detalhes",
            path: `/matricula/detalhes`,
          },
          {
            title: "Responsáveis",
            path: `/matricula/responsaveis`,
          },
          {
            title: "Financeiro",
            path: `/matricula/financeiro`,
          },
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Movimentações",
        path: `/movimentacoes`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiListStatus} size={1}/>
          </SvgIcon>
        ),
        items: [
          {
            title: "Atualização de Contrato",
            path: `/movimentacoes/atualizacao-de-contrato`,
          },
          {
            title: "Troca de Turma",
            path: `/movimentacoes/troca-de-turma`,
          },
          {
            title: "Troca de Curso",
            path: `/movimentacoes/troca-de-curso`,
          },
          {
            title: "Responsável Financeiro",
            path: `/movimentacoes/responsavel-financeiro`,
          },
          {
            title: "Plano de Pagamento",
            path: `/movimentacoes/plano-de-pagamento`,
          },
          {
            title: "Cancelamento",
            path: `/movimentacoes/cancelamento`,
          },
          {
            title: "Bolsas",
            path: `/movimentacoes/bolsas`,
          },
          {
            title: "Troca Método de Pagamento",
            path: `/movimentacoes/troca-metodo-pagamento`,
          },
          {
            title: "Troca Resp. Pedagógico",
            path: `/movimentacoes/troca-responsavel-pedagogico`,
          }
        ]
      },
    ]
  },
  // {
  //   items: [
  //     {
  //       title: "Conciliação de Matrícula",
  //       path: `/conciliacao`,
  //       icon: (
  //         <SvgIcon fontSize="small">
  //           <Icon path={mdiFileCompare} size={1} />
  //         </SvgIcon>
  //       ),
  //     },
  //   ]
  // },
  {
    items: [
      {
        title: "Declarações",
        path: `/declaracoes`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiFilePdfBox} size={1} />
          </SvgIcon>
        ),
        items: [
          {
            title: "Bolsa",
            path: `/declaracoes/bolsa`,
          },
          {
            title: "Raça e Cor",
            path: `/declaracoes/raca-cor`,
          },
          {
            title: "Carteirinha Escolar",
            path: `/declaracoes/carteirinha-escolar`,
          }
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Reconhecimento Facial",
        path: `/reconhecimento-facial`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiBadgeAccount} size={1} />
          </SvgIcon>
        ),
        items: [
          {
            title: "Alunos",
            path: `/reconhecimento-facial/alunos`,
          },
          {
            title: "Responsáveis",
            path: `/reconhecimento-facial/responsaveis`,
          },
          {
            title: "Publicidade",
            path: `/reconhecimento-facial/publicidade`,
          }
        ]
      },
    ]
  },
  {
    items: [
      {
        title: "Integração",
        path: `/integracao`,
        icon: (
          <SvgIcon fontSize="small">
            <Icon path={mdiCog} size={1} />
          </SvgIcon>
        ),
        items: [
          {
            title: "Replay Total",
            path: `/integracao/replay-total`,
          },
          {
            title: "Contrato",
            path: `/integracao/contrato`,
          },
          {
            title: "Replay Parcial",
            path: `/integracao/replay-parcial`,
          },
          {
            title: "Replay Total Upselling",
            path: `/integracao/replay-total-upselling`,
          },
          {
            title: "Replay Parcial Upselling",
            path: `/integracao/replay-parcial-upselling`,
          },
          {
            title: "Replay Parcial Regular",
            path: `/integracao/replay-parcial-regular`,
          },
          {
            title: "Replay Parcial Material",
            path: `/integracao/replay-parcial-material`,
          },
          {
            title: "Bloqueio de Recursos",
            path: `/integracao/bloqueio-de-recursos`,
          },
          {
            title: "Manutenção Financeira",
            path: `/movimentacoes/manutencao-financeira`,
          },
          {
            title: "Manutenção Financeira - Frank",
            path: `/integracao/manutencao-financeira`,
          },
          {
            title: "Replay Parcial Reserva",
            path: `/integracao/replay-parcial-reserva`,
          },
          {
            title: "Replay Total Reserva",
            path: `/integracao/replay-total-reserva`,
          },
        ]
      },
    ]
  }
];

export default function useSidebarMenus() {
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const permissions = useRecoilValue(permissionsState);
  const [appConfig] = useState(context);


  return useMemo( () => {
    const config = async () => {
      let p = [...permissions];
      let menuOpcoes = sections;
      let permiteMovimentar = permissions.some( p => p.permissionName === 'movimentacoes.all');

      const movimentacoesList = {
        "/movimentacoes/troca-responsavel-pedagogico": tipoMovimentacaoEnum.TrocaResponsavelPedagogico,
        "/movimentacoes/atualizacao-de-contrato": tipoMovimentacaoEnum.AtualizarContrato,
        "/movimentacoes/troca-de-turma": tipoMovimentacaoEnum.TrocaTurma,
        "/movimentacoes/responsavel-financeiro": tipoMovimentacaoEnum.TrocaResponsavelFinanceiro,
        "/movimentacoes/plano-de-pagamento": tipoMovimentacaoEnum.TrocaPlanoPagamento,
        "/movimentacoes/cancelamento": tipoMovimentacaoEnum.Cancelamento,
        "/movimentacoes/bolsas": tipoMovimentacaoEnum.TrocaBolsa,
        "/movimentacoes/troca-metodo-pagamento": tipoMovimentacaoEnum.TrocaMetodoPagamento,
        "/movimentacoes/troca-de-curso": tipoMovimentacaoEnum.TrocaCurso,
      }

      const integracoesList = {
        "/integracao/replay-total": "integração.replayTotal",
        "/integracao/contrato": "integração.contrato",
        "/integracao/replay-parcial": "integração.replayParcial",
        "/integracao/replay-total-upselling": "integração.replayTotalUpSelling",
        "/integracao/replay-parcial-upselling": "integração.replayParcialUpSelling",
        "/integracao/replay-parcial-regular": "integração.replayParcialRegular",
        "/integracao/replay-parcial-material": "integração.replayParcialMaterial",
        "/integracao/bloqueio-de-recursos": "integração.bloqueioDeRecursos",
        "/integracao/manutencao-financeira": "integração.manutencaoFinanceira",
        "/movimentacoes/manutencao-financeira": "integração.manutencaoFinanceira",
        "/integracao/replay-parcial-reserva": "integração.replayParcialReserva",
        "/integracao/replay-total-reserva": "integração.replayTotalReserva",
      }

      const declaracoesList = {
        "/declaracoes/carteirinha-escolar": "declaracoes.carteirinhaEscolar",
        "/declaracoes/bolsa": "declaracoes.bolsa",
        "/declaracoes/raca-cor": "declaracoes.racaCor",
      }

      const cadastroList = {
        "/reconhecimento-facial/alunos": "cadastro.reconhecimentoFacial",
        "/reconhecimento-facial/responsaveis": "cadastro.reconhecimentoFacial",
        "/reconhecimento-facial/publicidade": "cadastro.reconhecimentoFacial",
      }

      if (selectedUnidade && selectedUnidade.id) {
        const templates: {
          tipoMovimentacao: {
            valor: number;
          }
        }[] = await _movimentacaoService.GetAllTemplateMovimentacaoAtivos(selectedUnidade.id, permiteMovimentar);
        p.push({
          id: "db685d98-b45e-41ce-934d-acf900e66558",
          systemId: "a3b850c1-8912-4bcd-9b99-5fc8fde4d1c1",
          systemName: "SW",
          roleName: "",
          permissionId: "db685d98-b45e-41ce-934d-acf900e66558",
          permissionName: "declaracoes.bolsa"
        },
        {
          id: "5eae0689-1eec-40c8-9d84-ec80990ff8c0",
          systemId: "a3b850c1-8912-4bcd-9b99-5fc8fde4d1c1",
          systemName: "SW",
          roleName: "",
          permissionId: "5eae0689-1eec-40c8-9d84-ec80990ff8c0",
          permissionName: "declaracoes.racaCor"
        });
        //TODO: usar permissão no graph no futuro
        //@ts-ignore
        if (selectedUnidade?.possuiFacial) {
          p.push({
            id: "f9612df9-f2c2-4737-9035-22e27b33db50",
            systemId: "a3b850c1-8912-4bcd-9b99-5fc8fde4d1c1",
            systemName: "SW",
            roleName: "",
            permissionId: "f9612df9-f2c2-4737-9035-22e27b33db50",
            permissionName: "cadastro.reconhecimentoFacial"
          })
        }
        appConfig.carteirinhaEscolasPermitidas.forEach((schoolId: string) => {
          if (schoolId === selectedUnidade?.id) {
            p.push({
              id: "776b6ecf-9271-450f-8444-7c343f9c1093",
              systemId: "a3b850c1-8912-4bcd-9b99-5fc8fde4d1c1",
              systemName: "SW",
              roleName: "",
              permissionId: "776b6ecf-9271-450f-8444-7c343f9c1093",
              permissionName: "declaracoes.carteirinhaEscolar"
            })
          }
        });

        menuOpcoes = menuOpcoes.map(x => {
          if (x.items && x.items.length > 0) {
            const firstItem = x.items[0];
            if (firstItem.path === "/movimentacoes") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                const value = movimentacoesList[y.path];
                const hasTemplate = templates.some(z => z.tipoMovimentacao.valor === value);
                // @ts-ignore
                return hasTemplate || movimentacoesList[y.path] === undefined;
              });

              x.items[0] = firstItem;
            }

            if (firstItem.path === "/integracao") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                const value = integracoesList[y.path];
                // @ts-ignore
                return p.some(z => z.permissionName === value)
              });

              x.items[0] = firstItem;
            }

            if (firstItem.path === "/reconhecimento-facial") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                const value = cadastroList[y.path];
                // @ts-ignore
                return p.some(z => z.permissionName === value)
              });

              x.items[0] = firstItem;
            }

            if (firstItem.path === "/declaracoes") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                const value = declaracoesList[y.path];
                // @ts-ignore
                return p.some(z => z.permissionName === value)
              });

              x.items[0] = firstItem;
            }
          }
          return x;
        });

        // @ts-ignore
        menuOpcoes = menuOpcoes.map(x => {
          if (x.items && x.items.length > 0) {
            const firstItem = x.items[0];
            if (firstItem.path === "/integracao" && firstItem.items?.length === 0) {
              return null;
            }

            if (firstItem.path === "/reconhecimento-facial" && firstItem.items?.length === 0) {
              return null;
            }
          }
          return x;
        }).filter(x => x !== null);
      } else {
        menuOpcoes = menuOpcoes.map(x => {
          if (x.items && x.items.length > 0) {
            const firstItem = x.items[0];
            if (firstItem.path === "/movimentacoes") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                return movimentacoesList[y.path] === undefined;
              });

              x.items[0] = firstItem;
            }

            if (firstItem.path === "/integracao") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                return integracoesList[y.path] === undefined;
              });
              x.items[0] = firstItem;
            }

            if (firstItem.path === "/reconhecimento-facial") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                return cadastroList[y.path] === undefined;
              });

              x.items[0] = firstItem;
            }

            if (firstItem.path === "/declaracoes") {
              firstItem.items = firstItem.items?.filter(y => {
                // @ts-ignore
                return declaracoesList[y.path] === undefined;
              });

              x.items[0] = firstItem;
            }
          }
          return x;
        });
      }
      sessionStorage.setItem('@SECRETARIA/permiteMovimentar', JSON.stringify(permiteMovimentar));
      return menuOpcoes;
    }

    return config();
  }, [selectedUnidade]);
}
